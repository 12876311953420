<template>
	<div>
		<b-row class="ratingsRow 3" v-for="rating in filterDeletedRatings" v-bind:key="rating.id">
			<b-col cols="6">
					<span>{{$t('general.ratingSource')}}</span>
					<div class="spacer xs"></div>
					<b-dropdown 
							:text="ratingAgencyText(rating.ratingAgency.data.id)"
					>
							<b-dropdown-item v-for="option in getRatingRatingAgencyOptions(rating)" :key="option.value.id" :value="option.value.id" @click="selectRatingAgency(rating, option)">
							{{option.text}}
							</b-dropdown-item>
					</b-dropdown>
			</b-col>

			<b-col v-if="rating.ratingAgency.data.id" cols="5">
					<span>{{$t('general.rating')}}</span>
					<div class="spacer xs"></div>
					<input v-if="ratingAgencyType(rating.ratingAgency.data.id) === 'points'" v-bind:min="rating.ratingAgency.data.attributes.min" v-bind:max="rating.ratingAgency.data.attributes.max" type="number" v-model="rating.value" >
					<b-dropdown v-if="ratingAgencyType(rating.ratingAgency.data.id) === 'stars'"
							:text="ratingStarValueText(rating.value)"
					>
							<b-dropdown-item v-for="option in getRatingStarOptions(rating.ratingAgency.data.id)" :key="option.value" :value="option.value" @click="rating.value = option.value">
							{{ratingStarValueText(option.value)}}
							</b-dropdown-item>
					</b-dropdown>
			</b-col>

			<b-col cols="1" class="deleteCol">
					<div class="spacer xs"></div>
					<button class="iconButton" @click="flagDeleteRating(rating.id)" >
							<svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
									<path d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM3 6h8v10H3V6zm7.5-5-1-1h-5l-1 1H0v2h14V1h-3.5z" fill="#0CBA4A" fill-rule="nonzero"/>
							</svg>
					</button>
			</b-col>
		</b-row>

		<b-row class="addRating mt-2 mb-4">
			<b-col> 
				<button v-if="selectedWineCanAddWineRatingAgency" class="iconButton add" @click="addEmptyRating">
								{{$t('general.rating')}}
				</button>
			</b-col>
		</b-row>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'
import Constants from '@/utils/Constants'
import * as Utils from '@/utils/functions' 

export default {
    name: 'RatingsEdit',
    props: {
      selectedWine: Object,
    },
		created () {
        this.loadWineRatingOptions()
    },
		data () {
      return {
        wineRatingOptions: [],
				ratings: this.selectedWine.attributes.ratings,
      }
    },
    methods: {
			loadWineRatingOptions() {
				var wineRatingOptions = []
				this.getRatingAgencies.data.forEach((ratingAgency) => {
						var option = {
								value: ratingAgency.id,
								text: ratingAgency.attributes.title,
								ratingAgency: ratingAgency,
						}
						wineRatingOptions.push(option)
				})
				this.wineRatingOptions = wineRatingOptions
			},
			getRatingRatingAgencyOptions(rating) {
				let ratingAgencyOptions = [];
				
				const wineRatingOption = this.wineRatingOptions.find(wineRatingOption => {
						if (rating.ratingAgency.data) {
								return wineRatingOption.value == rating.ratingAgency.data.id
						}
						return false
				})

				if (rating.ratingAgency.data && rating.ratingAgency.data.id && wineRatingOption) {
					ratingAgencyOptions.push(wineRatingOption)
				}

				if (this.ratings != null) {
						this.wineRatingOptions.forEach((wineRatingOption) => { 
								const found = this.ratings.find(rating => {
										return rating.ratingAgency.data != null && rating.ratingAgency.data.id === wineRatingOption.value
								})
										
								if (typeof found == "undefined") {
									ratingAgencyOptions.push(wineRatingOption);
								}
						})
				}
				
				return ratingAgencyOptions;
			},
			ratingAgencyText(ratingAgencyId) {
				var text = this.$t('general.dropdownText')
				if (ratingAgencyId) {
						var ratingAgency = this.wineRatingOptions.find(ratingAgency => ratingAgency.value == ratingAgencyId)
						text = ratingAgency.text
				}
				return text
			},
			ratingAgencyType(ratingAgencyId) {
				var ratingType = ""
				if (ratingAgencyId) {
						var ratingAgency = this.wineRatingOptions.find(ratingAgency => ratingAgency.value == ratingAgencyId)
						ratingType = ratingAgency.ratingAgency.attributes.ratingType
				}
				return ratingType
			},
			getRatingStarOptions(ratingAgencyId) {
				let options = [];
				
				if (ratingAgencyId) {
						var ratingAgency = this.wineRatingOptions.find(ratingAgency => ratingAgency.value == ratingAgencyId)
						let min = ratingAgency.ratingAgency.attributes.min
						let max = ratingAgency.ratingAgency.attributes.max
						for (let i = min; i <= max; i++) {
								options.push({
										value: i
								});
						}
				}
				return options
			},
			ratingStarValueText(stars) {
				return stars + ' ' + (stars == 1 ? this.$t('general.star') : this.$t('general.stars'))
			},
			selectRatingAgency(rating, option) {
				rating.ratingAgency.data.id = option.value
				rating.value = option.ratingAgency.attributes.min
			},
			addEmptyRating() {
				if (this.ratings == null  || this.ratings == undefined) {
						//set empty array if there are not yet any prices
						this.ratings = [];
				}
				//create tempId: vuejs needs a key for its for-loop-list binding
				const tempId = `tempIdRating_${this.ratings.length}`

				this.ratings.push({
						id: tempId,
						ratingAgency : {
								data: {
										id: null,
										attributes: {
												title: '',
												ratingType: '',
												max: null, 
												min: null,
										}
								}
						},
						createNew: true,
						value: 0,
				})
			},
			flagDeleteRating(ratingID) {
				var ratings = JSON.parse(JSON.stringify(this.ratings))
				for (var i = 0; i < ratings.length; i++) {
						var rating = ratings[i]
						if (ratingID == rating.id) {
						rating[Constants.SHOULD_DELETE] = true
						ratings[i] = rating
						break
						}
				}
				//trigger compute method
				this.ratings = JSON.parse(JSON.stringify(ratings))
			},
		},
		computed: {
			...mapGetters(['getRatingAgencies']),
			selectedWineCanAddWineRatingAgency() {
					var foundRatingAgencies = 0
					if (this.ratings != null) {
							this.wineRatingOptions.forEach((ratingAgency) => {
									const found = this.ratings.find(rating => rating.ratingAgency.data && rating.ratingAgency.data.id === ratingAgency.value)                    
									if (typeof found !== "undefined") {
											foundRatingAgencies++
									}
							});
					}
				
					const foundAllRatingAgencies = this.wineRatingOptions.length > 0 && foundRatingAgencies == this.wineRatingOptions.length
					return !foundAllRatingAgencies
			},
			filterDeletedRatings() {
				return Utils.getNotDeletedRatings(this.ratings);
			},
		},
}

</script>