<template>
	<b-modal :id="modalId" size="xl" @show="resetModal" @hidden="resetModal" hide-footer>
		<template #modal-title>
			{{ $t('winelist.addWineModal.title') }}
		</template>

		<template #default>
			<div>
				<StepNavigation :step="step" />
				<div @click="clickOutSide()" class="modal-inner-content">
					<Step1 v-if="step === 1" @cancel="hideModal" @okFromStep1="handleOkFromStep1" />
					<Step2 v-if="step === 2" @backFromStep2="handleBackFromStep2" @okFromStep2="handleOkFromStep2" :selectedWinery="selectedWinery" :selectedWines="selectedWines" />
					<Step3 v-if="step === 3" @backFromStep3="handleBackFromStep3" @okFromStep3="handleOkFromStep3" :selectedWinery="selectedWinery" :selectedWines="selectedWines"  />
					<Step4 v-if="step === 4" @backFromStep4="handleBackFromStep4" @okFromStep4="handleOkFromStep4" :selectedWinery="selectedWinery" :selectedWines="selectedWines" />
				</div>
			</div>
		</template>

	</b-modal>
</template>

<script>
import StepNavigation from '@/components/modals/addWine/StepNavigation.vue'
import Step1 from '@/components/modals/addWine/step1/Step1.vue'
import Step2 from '@/components/modals/addWine/step2/Step2.vue'
import Step3 from '@/components/modals/addWine/step3/Step3.vue'
import Step4 from '@/components/modals/addWine/step4/Step4.vue';

export default {
	name: 'CreateWinesModal',
	data() {
		return {
			modalId: 'create-wines-modal',
			step: 1,
			selectedWinery: {},
			selectedWines: [],
		}
	},
	components: {
		StepNavigation,
		Step1,
		Step2,
		Step3,
		Step4
	},
	methods: {
		handleOkFromStep1(winery) {
			this.selectedWinery = winery
			this.step = 2
		},
		handleOkFromStep2(selectedWines) {
			this.selectedWines = selectedWines
			this.step = 3
		},
		handleBackFromStep2() {
			this.resetModal()
		},
		handleOkFromStep3(selectedWines) {
			this.selectedWines = selectedWines
			this.step = 4
		},
		/**
		 * Handle the back event from step 3
		 * override the selected wines with the complemented wines to keep the user's
		 * inputs from step 3
		 */
		handleBackFromStep3(selectedWines) {
			this.selectedWines = selectedWines
			this.step = 2
		},
		handleOkFromStep4() {
			this.hideModal()
			this.$emit('addedNewVenueWines')
		},
		handleBackFromStep4() {
			this.step = 3
		},
		showModal() {
			this.$bvModal.show(this.modalId)
		},
		hideModal() {
			this.$bvModal.hide(this.modalId)
			this.step = 1
			this.resetModal()
		},
		resetModal() {
			this.selectedWinery = {}
			this.selectedWines = []
			this.step = 1
		},
		clickOutSide() {
      this.$store.commit('clickedOutside')
    },
	}
}

</script>