<template>
	<div class="summary-wrapper">
		<div class="summary-title">
			<h4 class="winery-title d-inline">{{ selectedWinery.title }}</h4>
			<p class=" ml-1 d-inline">{{ selectedWinery.region.title }}, {{ selectedWinery.land.title }}</p>
		</div>
		<LoadingAnimation v-if="loading" />
		<div class="wines-container" v-if="loading == false">
			<b-row class="table-head-summary">
				<div class="col-number">
					<p>{{ $t('winelist.addWineModal.number_short') }}</p>
				</div>

				<div class="col-title" style="flex-grow: 2">
					<p>{{ $t('general.name') }}</p>
				</div>
				<div class="col-grape-variety" style="flex-grow: 2">
					<p>{{ $t('general.grapeVariety') }}</p>
				</div>
				<div class="col-selected-year">
					<p>{{ $t('general.year') }}</p>
				</div>
				<div class="col-selected-bottlesize">
					<p>{{ $t('general.size') }}</p>
				</div>
				<div class="col-wine-amount">
					<p>{{ $t('general.amount_new') }}</p>
				</div>
				<div class="col-selected-wine-supplier">
					<p>{{ $t('general.supplier') }}</p>
				</div>
				<div class="col-shelf">
					<p>{{ $t('general.shelf') }}</p>
				</div>
				<div class="col-ek">
					<p>{{ $t('general.ekNet') }}</p>
				</div>
				<div class="col-vk">
					<p>{{ $t('general.vkGross') }}</p>
				</div>
				<div class="col-weq">
					<p>{{ $t('general.weq') }}</p>
				</div>
			</b-row>
			<b-row class="wine-row uploadable-wines" v-for="(wine, index) in uploadableWines" :key="index"
				:class="{ error: wine.alert.alertIsVisible }">
				<div class="col-number">
					<p>{{ index + 1 }}</p>
				</div>
				<div class="col-title" style="flex-grow: 2">
					<p>{{ wine.title }}</p>
				</div>
				<div class="col-grape-variety" style="flex-grow: 2">
					<p>{{ limitString(18, wine.grapeVariety.title) }}</p>
				</div>
				<div class="col-selected-year">
					<p>{{ showYearValue(wine) }}</p>
				</div>
				<div class="col-selected-bottlesize">
					<p>{{ wine.selectedBottlesize.attributes.amount }} {{ $t('general.liter_short') }}</p>
				</div>
				<div class="col-wine-amount">
					<p>{{ wine.wineAmount }}</p>
				</div>
				<div class="col-selected-wine-supplier">
					<p>{{ limitString(21, wine.selectedWineSupplier.title) }}</p>
				</div>
				<div class="col-shelf">
					<p>{{ wine.shelf }}</p>
				</div>
				<div class="col-ek">
					<p>{{ wine.ek }}</p>
				</div>
				<div class="col-vk">
					<p>{{ wine.vk }}</p>
				</div>
				<div class="col-weq">
					<Weq :purchasePriceAvg="wine.ek" :sellingPrice="wine.vk" />
				</div>
				<b-alert fade variant="danger" :show="wine.alert.alertIsVisible">
					{{ wine.alert.alertMessage }}
				</b-alert>
			</b-row>

		</div>
	</div>
</template>

<script>
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import DataMappingService from '@/services/DataMappingService'

import { mapGetters } from 'vuex'
import DataService from '@/services/StrapiService'
import { applyMandatoryRatingAgencies, addSupplierAndPrice } from '@/utils/venueWineFunctions'
import { limitString } from '@/utils/functions'

import Weq from '@/components/wineDetail/Weq.vue';

export default {
	name: 'Summary',
	components: {
		Weq,
		LoadingAnimation,

	},
	props: {
		selectedWines: {
			type: Array,
			required: true
		},
		selectedWinery: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			uploadableWines: this.selectedWines,
			loading: false,
		}
	},
	mounted() {
	},
	methods: {
		limitString,
		resetAlert() {
			for (const wine of this.uploadableWines) {
				wine.alert = {
					alertIsVisible: false,
					alertMessage: ''
				}
			}
		},
		showAlertInWineRow(responseWineId, responseBottleSizeId, message) {
			const index = this.uploadableWines.findIndex(wine => wine.id === responseWineId && wine.selectedBottlesize.id === responseBottleSizeId)
			this.uploadableWines[index].alert = {
				alertIsVisible: true,
				alertMessage: message,
			}
			this.updateValues(index)
		},
		updateValues(index) {
			this.$set(this.uploadableWines, index, this.uploadableWines[index])
		},
		showLoading() {
			this.loading = true
		},
		hideLoading() {
			this.loading = false
		},
    showYearValue(wine) {
      if (wine.selectedYear === 0) {
        return this.$t('general.notDefined')
      } else {
        return wine.selectedYear
      }
    },
		async saveWines() {
			this.showLoading()
			let saveStatus = 'saved';	/* default value for the function to return, only in error case changed */

			for (let i = 0; i < this.uploadableWines.length; i++) {
				const wine = this.uploadableWines[i];

				var venueWineObject = {
					attributes: {
						title: wine.title.trim(),
						venue: this.getVenue.id,
						bottleSize: wine.selectedBottlesize.id,
						year: wine.selectedYear,
						wine: wine.id,
						ratings: [],
						sellingPrice: wine.vk,
						amount: wine.wineAmount,
						shelfNumber: wine.shelf,
					}
				}
				applyMandatoryRatingAgencies(venueWineObject, this.getRatingAgencies)
				venueWineObject = addSupplierAndPrice(venueWineObject, wine.ek, wine.selectedWineSupplier)
				const createVenueWineResponse = await DataService.createVenueWine(venueWineObject)
				const mappedResponse = DataMappingService.mapVenueWineApiToVenueWineUi(createVenueWineResponse)
				const responseWineId = mappedResponse.wine.id
				const responseBottleSizeId = mappedResponse.bottleSize.id

				this.hideLoading()

				if (createVenueWineResponse.status !== 200) {
            this.showAlertInWineRow(responseWineId, responseBottleSizeId, this.$t('errors.dataNotValid'))
            saveStatus = 'error';
        }
			}
			return saveStatus;

		},
	},
	computed: {
		...mapGetters(['getVenue', 'getRatingAgencies'])

	}
}
</script>
<style scoped>
.wines-container p,
.wines-container span

/* for WEQ */
	{
	color: var(--color-gray-100) !important;
	font-size: var(--font-xs);
	font-weight: normal;
}

.error p,
.error span {
	color: var(--color-danger) !important;
}

.row {
	min-height: 40px;
}

.table-head-summary p {
	font-weight: 700;
}

h4.winery-title {
	margin-right: var(--margin-xs);
}

.summary-title {
	margin-bottom: var(--margin-m);
}


.col-number {
	width: 28px !important;
}

.col-title {
	width: 270px !important;
}

.col-grape-variety {
	width: 108px !important;
}

.col-selected-year {
	width: 50px !important;
}

.col-selected-bottlesize {
	width: 60px !important;
}

.col-wine-amount {
	width: 60px !important;
}

.col-selected-wine-supplier {
	width: 140px !important;
}

.col-shelf {
	width: 48px !important;
}

.col-ek {
	width: 90px !important;
}

.col-vk {
	width: 90px !important;
}

.col-weq {
	width: 60px !important;
}
</style>