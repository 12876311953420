<template>
  <div class="choose-wine-table-container modalView">
    <b-table ref="chooseWineTable" sticky-header sort-icon-left
      :items="winesToChooseFrom" :fields="wineFields" show-empty :busy="isLoading"
			:tbody-tr-class="getWineRowBodyClass" @row-clicked="onWineRowClicked">
      <template #table-busy>
        <div class="text-center my-2">
          <LoadingAnimation />
        </div>
      </template>
      <template #empty>
        <h4>{{ $t('winelist.addWineModal.noResultsWine') }}</h4>
      </template>
      <template #cell(wineType.localeKey)="data">
        <span v-if="data.value">{{ $t('wineTypes.' + data.value) }}</span>
        <span v-else>{{ data.item.wineType.title }}</span>
      </template>
      <template #cell(grapeVariety.localeKey)="data">
        <span>{{ $t(data.item.grapeVariety.title)}}</span>
      </template>
     
     
      <template #cell(selectedWine)="{ rowSelected }">

        <template v-if="rowSelected">
          {{ selectedWine }}
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
    <AddWineRow :selectedWinery="winery" @wineAdded="handleWineAdded" />
  </div>
</template>

<script>
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import DataService from '@/services/StrapiService'
import DataMappingService from '@/services/DataMappingService'
import AddWineRow from '@/components/modals/addWine/step2/AddWineRow.vue'
import * as Utils from '@/utils/functions'

export default {
  name: 'ChooseWine',
  props: {
    selectedWinery: {
      type: Object,
      required: true
    },
		selectedWines: {
			type: Array,
			required: true
		},
  },
  data() {
    return {
			isLoading: false,
      wineSearch: '',
      winery: {},
      wines: [],
      wineFields: [
        {
          key: 'title',
          label: this.$t('general.wine'),
          sortable: true
        },
        {
          key: 'wineType.localeKey',
          label: this.$t('general.color'),
					sortable: true
        },
        {
          key: 'grapeVariety.localeKey',
          label: this.$t('general.grapeVariety'),
					sortable: true
        }
      ]
    }
  },
	created() {
		this.initWines()
	},
  mounted() {
    this.$root.$on('changedWineSearch', (val) => {
      this.wineSearch = val
    })
  },
  components: {
    AddWineRow, LoadingAnimation
  },
  methods: {
		/**
		 * Initialize the wines with the already selected and maybe already
		 * complemented wines when coming back from the step 3
		 */
		async initWines() {
      this.winery = this.selectedWinery
			this.isLoading = true
			const response = await DataService.getWineryPopulated(this.selectedWinery.id)
			this.winery = DataMappingService.mapGetWineryResponse(response)
			const wines = this.winery.wines
			this.wines = this.addDataFromSelectedWines(wines)
			this.isLoading = false
		},
		/**
		 * Add wine data and duplicated wines of step3 to the wines
		 * this is needed when coming back from step 3
		 * @param {*} wines 
		 */
		addDataFromSelectedWines(wines) {
			let tempWines = Utils.deepCopyObject(wines)
			tempWines.forEach((wine) => {
				const selectedWine = this.selectedWines.find((selected) => selected.id === wine.id && this.wineIsNotDuplicated(selected));
				if (selectedWine) {
					Object.assign(wine, selectedWine);
				}
			});

			const duplicates = this.selectedWines.filter((wine) => wine.isDuplicate === true)

			duplicates.forEach((duplicatedWine) => {
				const originalIndex = this.findIndexOfOriginalWine(tempWines, duplicatedWine)
				const alreadyInList = this.isDuplicatedWineInWines(tempWines, duplicatedWine)
				if (originalIndex >= 0 && alreadyInList === false) {
					//add duplicated wine after the original wine
					tempWines.splice(originalIndex + 1, 0, duplicatedWine)
				}
			})
			return tempWines
		},
		findIndexOfOriginalWine(wines, duplicatedWine) {
			return wines.findIndex((wine) => wine.id === duplicatedWine.id && this.wineIsNotDuplicated(wine))
		},
		findIndexOfDuplicatedWine(wines, duplicatedWine) {
			return wines.findIndex((wine) => wine.id === duplicatedWine.id && wine.isDuplicate === true)
		},
		isDuplicatedWineInWines(wines, duplicatedWine) {
			const foundWine = wines.find((wine) => wine.id === duplicatedWine.id 
				&& wine.title === duplicatedWine.title
				&& wine.selectedYear === duplicatedWine.selectedYear
				&& wine.selectedBottlesize.id === duplicatedWine.selectedBottlesize.id
				&& wine.isDuplicate === true)
			return foundWine !== undefined
		},
		wineIsNotDuplicated(wine) {
			return Object.hasOwnProperty.call(wine, 'isDuplicate') === false
		},
		wineIsNotVenueSpecific(wine) {
			return wine.venue.id === null
		},
    onWineRowClicked(wine) {
      this.toggleSelectWineRow(wine)
    },
    handleWineAdded(wine) {
      this.wines.push(wine)
			console.log(wine)
			this.toggleSelectWineRow(wine)
    },
		toggleSelectWineRow(wine) {
			wine.isSelected = !wine.isSelected
			const index = this.wines.findIndex((w) => w.id === wine.id)
			this.$set(this.wines, index, wine)
		},
		getWineRowBodyClass(wine) {
			const isSelected = wine != null && wine.isSelected
			return {
					"b-table-row-selected": isSelected,
				}
		},
  },
  computed: {
    winesToChooseFrom() {
      return this.wines.filter((wine) => wine.title.toLowerCase().includes(this.wineSearch.toLowerCase()) 
				&& this.wineIsNotVenueSpecific(wine) && this.wineIsNotDuplicated(wine))
    },
  },
  watch: {
    wines: {
			/**
			 * Filter newly selected wines or already selected wines
			 * Add saved duplicate wines from step 3
			 * @param {*} val 
			 */
			handler(val) {
				const selectedWines = val.filter((wine) => wine.isSelected)
				this.$emit('onSelectedWines', selectedWines)
			},
			immidiate: true
		}
  },
}

</script>