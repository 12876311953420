<template>
	<div>
		<h2>{{ $t('winelist.addWineModal.step4') }}</h2>

		<Summary ref="summary" :selectedWines="selectedWines" :selectedWinery="selectedWinery" />

		<div class="step-footer">
			<b-button class="back" @click="backClick()">
				{{ $t('general.buttons.back') }}
			</b-button>
			<b-button :disabled="continueNotPossible" variant="success" @click="okClick()">
				{{ this.$tc('winelist.addWineModal.addWine', this.selectedWines.length) }}
			</b-button>
		</div>
	</div>
</template>

<script>

import Summary from '@/components/modals/addWine/step4/Summary.vue'

export default {
	name: 'Step4',
	props: {
		selectedWines: {
			type: Array,
			required: true
		},
		selectedWinery: {
			type: Object,
			required: true
		},
	},

	data() {
		return {
			wineries: [],
			backButtonVisible: true,
			continueNotPossible: false,
		}
	},
	components: {
		Summary,
	},
	methods: {
		backClick() {
			this.$refs.summary.resetAlert()
			this.$emit('backFromStep4');
		},
		async okClick() {
			const savedWines = await this.$refs.summary.saveWines()
			if (savedWines === 'saved') {
				this.$emit('okFromStep4');
			}
		},
	}
}

</script>