<template>
  <div>
    <b-row align-v="center">
			<b-col>
				<h5>{{ $t('winelist.addWineModal.searchWine') }}</h5>
			</b-col>
      <b-col cols="4">
        <div class="searchContainer">
          <b-form-input class="wineSearch" ref="wineSearch" v-model="wineSearch"
            :placeholder="$t('general.filter')"></b-form-input>
          <span v-if="wineSearch != null && wineSearch.length > 0" @click="wineSearch = ''">
            <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.728 2.243a6.002 6.002 0 0 1 0 8.485 6.002 6.002 0 0 1-8.485 0 6.002 6.002 0 0 1 0-8.485 6.002 6.002 0 0 1 8.485 0zM9.116 4.788l-.848-.848L6.57 5.637 4.874 3.94l-.849.848 1.697 1.697-1.697 1.697.849.849L6.57 7.334 8.268 9.03l.848-.849L7.42 6.485l1.697-1.697z"
                fill="#CED4DA" fill-rule="nonzero" />
            </svg>
          </span>
        </div>
      </b-col>
    </b-row>
  </div>


</template>

<script>


export default {
  name: 'ChooseWineSearch',
  data() {
    return {
      wineSearch: '',
    }
  },
  components: {
  },
  methods: {
  },
  computed: {
  },
  watch: {
    wineSearch: function (val) {
      this.$root.$emit('changedWineSearch', val)
    }
  },
}

</script>