<template>
	<div class="step-navigation">
		<div v-for="(title, index) in stepTitles" :key="index"
			:class="{ active: (index + 1) === step, finished: (index + 1) < step }" class="step">
			<span v-if="!finishedStep(index)" class="step-index">{{ index + 1 }}</span>
			<span v-if="finishedStep(index)" class="step-index"><img src="../../../assets/img/icon-check.svg"></span>
			<span class="step-title">{{ title }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StepNavigation',
	data() {
		return {
			stepTitles: [this.$t('winelist.addWineModal.step1'), this.$t('winelist.addWineModal.step2'), this.$t('winelist.addWineModal.step3'), this.$t('winelist.addWineModal.step4')],
		}
	},
	props: {
		step: {
			type: Number,
			required: true
		}
	},
	components: {

	},
	methods: {
		finishedStep(index) {
			return (index + 1) < this.step
		}
	},
	computed: {

	}
}
</script>

<style scoped>
.step-navigation {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background: var(--color-gray-300);
	color: var(--color-gray-200);
}

.step {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: calc(var(--margin-m) / 2) var(--margin-m) ;
	background: var(--color-gray-300);
}

.step-index {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--color-gray-200);
	border-radius: 50%;
	height: 24px !important;
	width: 24px !important;
	margin-right: 10px;
	font-size: 14px;
}

.active .step-index {
	border: 1px solid var(--color-primary-500);
	color: var(--color-white);
	background-color: var(--color-primary-500);
}
.finished .step-index {
	border: 1px solid var(--color-primary-500);
	background-color: none;
}

.step-title {
	font-weight: 700;
	text-transform: uppercase;
}

.active,
.finished {
	color: var(--color-primary-500);
	background: var(--color-gray-400);
}
</style>
