<template>
    <div @click="closePressed(selectedWine.attributes.amount, tempselectedWine.attributes.amount); amountIsDirectlySet = false" :class="'wineDetailOverlay ' + detailsClass">
    <div @click="function funct(event) {event.stopPropagation();}" class="wineDetailSidebar"  ref="wineDetailSidebar">
          <b-row v-if="selectedWine.id != null && selectedWineWinery.id != null" class="innerContainer">
              <div class="editMode" v-if="wineEditMode">
                  <div class="confirmEditMode">
                    <b-button @click="cancelEditVenueWine()" variant="danger">{{$t('general.buttons.cancel')}}</b-button> 
                    <b-button @click="updateVenueWine" variant="success">{{$t('general.buttons.save')}}</b-button>
                  </div>
                  <b-row class="mb-3">
                    <b-col cols="6">{{$t('general.winetitle')}}</b-col>
                    <b-col cols="6">{{$t('general.vintage')}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6"><input v-model="selectedWine.attributes.title"></b-col>
                    <b-col cols="6">
                        <YearDropdown @year-changed="changeYear"  :selectedYear="selectedWine.attributes.year" />
                    </b-col>
                  </b-row>
                  <div class="spacer s" />
                  <b-row class="mb-3">
                    <b-col cols="6">{{$t('general.shelf')}}</b-col>
                    <b-col cols="6">{{$t('general.articleNumber')}}</b-col>
                  </b-row>
                  <b-row class="mb-3">
                      <b-col cols="6"><input v-model="selectedWine.attributes.shelfNumber"></b-col>
                      <b-col cols="6"><input v-model="selectedWine.attributes.articleNumber"></b-col>
                  </b-row>

                  <div class="spacer s"/>
                  
                  <PurchasePricesEdit ref="purchasePricesEdit" 
                      :selectedWine="selectedWine" 
                      :venueSupplierOptions="venueSupplierOptions" 
                      :currency="getCurrency" 
                      @purchasePricesAvgChanged="setPurchasePricesAvg"
                  />
                      
                  <div class="spacer s"/>
                  
                  <SellingPricesEdit ref="sellingPricesEdit" 
                      :selectedWine="selectedWine" 
                      :currency="getCurrency"
                      class="mb-3"
                      @tempSellingPrice="setTempSellingPrice"
                  />

                  <div class="row mb-4">
                    <b-col cols="6">
                        <div>{{ $t('general.weq') }}</div>
                        <Weq :purchasePriceAvg="tempPurchasePricesAvg" :sellingPrice="tempSellingPrice"/>
                    </b-col>
                    <b-col cols="6">
                        <div>{{ $t('general.netprofit') }}</div>
                        <NetProfit :purchasePriceAvg="tempPurchasePricesAvg" :sellingPrice="tempSellingPrice" :currceny="getCurrency"/>
                    </b-col>
                </div>

                  <div class="spacer s" />
                  
                  <RatingsEdit ref="ratingsEdit" 
                      :selectedWine="selectedWine" 
                  />
  
                  <div class="spacer s"/>
                  <b-row>
                    <b-col>
                        <span>{{$t('general.tastingNote')}}</span>
                        <textarea v-model="selectedWine.attributes.comments">
                        </textarea>
                    </b-col>
                  </b-row>
              </div>
  
              <div class="venueWineDetailContainer">
                  <div class="card-header">
                          <div v-if="wineEditMode == true" class="editModeOverlay"></div>
                          <div class="wineryImageContainer">
                              <span @click="toggleFavorite" :class="'favButton ' + selectedWine.attributes.favorite">
                              </span>
                              <img v-if="selectedWineWinery.attributes.image != null && selectedWineWinery.attributes.image[0] != null" :src='strapiUrl + selectedWineWinery.attributes.image[0].url'>
                              <span class="lightMedium" v-else>
                                      {{selectedWineWinery.attributes.title}}
                              </span>
                              <span class="wineTitle">{{selectedWine.attributes.title}}</span>
                          </div>
                  </div>
                      <h2>{{$t('detailPanel.headlineSell')}}</h2>
                      <div class="box">
                        <div class="changeBottles">
                          <h3>{{$t('general.amount')}} {{$tc('general.bottle', 2)}}
                            <span class="changeAmountInfo" v-if="nowEditingBottles">
                                {{displayTempReducedBottles}}
                                      <span v-if="tempReducedBottles > 1">{{$t('detailPanel.bottlesAreReduced')}} </span>
                                      <span v-if="tempReducedBottles == 1">{{$t('detailPanel.bottleIsReduced')}} </span>
                                      <span v-if="tempReducedBottles == 0">{{$t('detailPanel.bottlesStay')}} </span>
                                      <span v-if="tempReducedBottles == -1">{{$t('detailPanel.bottleIsAdded')}} </span>
                                      <span v-if="tempReducedBottles < -1">{{$t('detailPanel.bottlesAreAdded')}} </span>
                            </span>
                        </h3>
                            <div style="justify-content: space-between" class="changeAmountContainer">
                                    <div class="amountContainer">
                                            <button :disabled="disableAmountControls()  || amountIsDirectlySet === true" @click="changeBottleAmount('reduce')">
                                                    <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14 2H0V0h14z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                    </svg>
                                            </button>
                                            <input @keyup="updateTempReducedBottles()" :disabled="disableAmountControls()" type="number" v-model="selectedWine.attributes.amount">
                                            <button :disabled="disableAmountControls() || amountIsDirectlySet === true" @click="changeBottleAmount('add')">
                                                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                </svg>
                                            </button>
                                    </div>
                                    <div v-if="selectedWine.attributes.amount !== tempselectedWine.attributes.amount">
                                            <button :disabled="nowEditingGlasses || selectedWine.attributes.amount < 0" @click="lockAmount(true)" class="acceptChange"></button>
                                            <button :disabled="nowEditingGlasses" @click="lockAmount(false)" class="cancelChange"></button>
                                    </div>   
                            </div>
                        </div>
                          <b-form-checkbox :disabled="nowEditingGlasses || nowEditingBottles" v-model="selectedWine.attributes.glassMode" @change="changeGlassMode()" name="check-button" switch>
                                  {{$t('detailPanel.glaswise')}}
                          </b-form-checkbox>
                          <div class="spacer m"></div>
                          <div class="changeGlasses" v-if="selectedWine.attributes.glassMode === true">
                              <h3>{{$t('detailPanel.amountOfGlasses')}} 
                                      <span class="changeAmountInfo" v-if="nowEditingGlasses">
                                     {{tempReducedGlasses}}
                                      <span v-if="tempReducedGlasses != 1">{{$t('detailPanel.glassesAreReduced')}} </span>
                                      <span v-if="tempReducedGlasses == 1">{{$t('detailPanel.glasIsReduced')}} </span>
                                      </span>
                              </h3>
                              <div style="justify-content: space-between"  class="changeAmountContainer glasses">
                                      <div class="amountContainer">
                                          <button :disabled="nowEditingBottles || !selectedWine.attributes.glassPrice" @click="changeGlassAmount('reduce')">
                                                  <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M14 2H0V0h14z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                  </svg>
                                          </button>
                                          <input disabled type="number" :value="selectedWine.attributes.amountGlasses">
                                          <button :disabled="nowEditingBottles || !selectedWine.attributes.glassPrice" @click="changeGlassAmount('add')">
                                                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                  </svg>
                                          </button>
                                      </div>
                                      <div v-if="nowEditingGlasses">
                                          <button :disabled="nowEditingBottles" @click="lockAmount(true)" class="acceptChange"></button>
                                          <button :disabled="nowEditingBottles" @click="lockAmount(false)" class="cancelChange"></button>
                                      </div>    
                              </div>
                          </div>
                  </div>
                  <b-modal size="md" id="modal-moveWine" :title="$t('detailPanel.moveModal.title')">
                          <div class="moveWineContainer row">
                                  <b-col cols="4">
                                          <p>{{$t('general.amount')}}</p>
                                          <div class="spacer m"></div>
                                          <div class="changeAmountContainer">
                                                  <button :disabled="amountIsDirectlySet === true" @click="changeMoveWineAmmount(-1)">
                                                          <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M14 2H0V0h14z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                          </svg>
                                                  </button>
                                                  <input @keydown="amountIsDirectlySet = true" type="number" v-model="wineMoveAmount">
                                                  <button :disabled="amountIsDirectlySet === true" @click="changeMoveWineAmmount(1)">
                                                          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                          </svg>
                                                  </button>
                                          </div>
                                  </b-col>
                                  <b-col>
                                          <p>{{$t('detailPanel.moveModal.text')}}</p>
                                          <div class="spacer m"></div>
                                          <b-dropdown
                                                  :text="movementText"
                                          >
                                                  <b-dropdown-item v-for="option in venuesForWineMove" :key="option.value" :value="option.value" @click="selectedVenueForWineMovement = option.value; movementText = option.text">
                                                          {{option.text}}
                                                  </b-dropdown-item>
                                          </b-dropdown>
                                  </b-col>   
                          </div>
                          <template #modal-footer="{ cancel }">
                                  <!-- Emulate built in modal footer ok and cancel button actions -->
                                  <b-button size="sm" variant="danger" @click="cancel()">
                                          {{$t('general.buttons.cancel')}}
                                  </b-button>
                                  <b-button size="sm" variant="success" @click="acceptWineMovement()" :disabled="selectedVenueForWineMovement == null">
                                          {{$t('detailPanel.moveWines')}}
                                  </b-button>
                          </template>
                  </b-modal>
                  
                  <b-modal size="md" id="modal-reduceWineAmount" :title="$t('detailPanel.reduceModal.title')">
                         
                          <div class="moveWineContainer row">
                                  <b-col cols="6">
                                    <p>{{$t('detailPanel.reduceModal.text')}}</p>
                                    <div class="spacer m"></div>
                                          <div class="changeAmountContainer">
                                                  <button :disabled="amountIsDirectlySet === true" @click="changeMoveWineAmmount(-1)">
                                                    <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14 2H0V0h14z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                    </svg>
                                                  </button>
                                                  <input @keydown="amountIsDirectlySet = true" type="number" v-model="wineMoveAmount">   
                                                  <button :disabled="amountIsDirectlySet === true" v-if="wineMoveAmount<selectedWine.attributes.amount" @click="changeMoveWineAmmount(1)">
                                                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                    </svg>
                                                  </button> 
                                          </div>
                                  </b-col>
                                   <b-col>
                                          <p>{{$t('detailPanel.reduceModal.causeOfReduction')}}</p>
                                          <div class="spacer m"></div>
                                          <CauseOfReduction @selectedCauseOfReduction="selectedCauseOfReduction = $event"/>
                                  </b-col>  
                                  <div class="spacer m"></div>
                          </div>
                          <template #modal-footer="{}">
                                  <!-- Emulate built in modal footer ok and cancel button actions -->
                                  <b-button size="sm" variant="danger" @click="declineWinereducing()">
                                    {{$t('general.buttons.cancel')}}
                                  </b-button>
                                  <b-button size="sm" variant="success" @click="acceptWineReducing()" :disabled="wineMoveAmount>selectedWine.attributes.amount || wineMoveAmount < 1">
                                    {{$t('detailPanel.reduceModal.confirmButton')}}
                                  </b-button>
                          </template>
                  </b-modal>
                  <b-modal size="md" id="modal-sellWithDiscount" :title="$t('detailPanel.discountModal.title')">
                      
                      <div class="sellWithDiscountContainer discountContainer row">
                      <b-col cols="4"> 
                                              <p>{{$t('general.amount')}}</p>
                                              <div class="spacer m"></div>
                                              <div class="changeAmountContainer">
                                              <button @click="changeDiscountAmmount(-1)">
                                                          <svg width="14" height="2" viewBox="0 0 14 2" xmlns="http://www.w3.org/2000/svg">
                                                                          <path d="M14 2H0V0h14z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                          </svg>
                                                  </button>
                                              <input ref="discountModalInput" type="number" disabled :value="wineDiscountQuantity">
                                              <button @click="changeDiscountAmmount(1)">
                                                          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M14 8H8v6H6V8H0V6h6V0h2v6h6z" fill="#0CBA4A" fill-rule="nonzero"/>
                                                          </svg>
                                                  </button>
                                      </div>
                              </b-col>
                              <b-col cols="8">
                                      <p>
                                      <i18n path="detailPanel.discountModal.text" >
                                      <template v-slot:currency>
                                              {{getCurrency}}
                                      </template>
                                      </i18n>
                                      </p>
                                      <div class="spacer m"></div>
                                      <input ref="inputFocus"  min="0" type="number" :max="selectedWine.attributes.sellingPrice" @change="calculateDiscount()" v-model="discount">
                                      <div v-if="parseInt(discount) > selectedWine.attributes.sellingPrice" class="spacer xs"></div>
                                      <p v-if="parseInt(discount) > selectedWine.attributes.sellingPrice" class="warning">{{$t('detailPanel.discountModal.discountTooHigh')}}</p>
                              </b-col>
                      </div>
                      <div class="discountCalculationContainer discountContainer row">
                              <b-col class="col-3">   
                                      <p class="colored">{{selectedWineWinery.attributes.title}}</p> 
                                      <p class="bold">{{selectedWine.attributes.title}}</p> 
                              </b-col>
                              <b-col class="col-4">
                                      <p class="colored colored text-right">{{selectedWine.attributes.bottleSize.amount}}</p>
                                      <p class="colored light text-right">{{selectedWine.attributes.year}}</p>
                              </b-col>
                              <b-col class="col-3">
                                      <p>{{$t('detailPanel.discountModal.sellingPrice')}}</p>
                                      <p>{{$t('detailPanel.discountModal.sellingPriceWithDiscount')}}</p>
                                      <p class="light">{{discountPercentage}} % {{$t('detailPanel.discountModal.discountPercentage')}}</p>
                              </b-col>
                              <b-col class="col-2">
                                      <p class="right bold">{{selectedWine.attributes.sellingPrice}}{{getCurrency}}</p>
                                      <p class="right bold">{{selectedWine.attributes.discountSellingPrice}}{{getCurrency}}</p>
                              </b-col>
                              <div class="col-12 discountDivider"></div>
                              <b-col class="col-7"><p class="bold">{{wineDiscountQuantity}} {{$t('detailPanel.discountModal.bottleQuantity')}}</p></b-col>
                              <b-col class="col-3"><p>{{$t('detailPanel.discountModal.discountSellingPrice')}}</p></b-col>
                              <b-col class="col-2"><p class="right bold">{{discountPriceTotal}}{{getCurrency}}</p></b-col>
                          
                      </div>
                      
                      <template #modal-footer="{ cancel }">
                              <!-- Emulate built in modal footer ok and cancel button actions -->
                              <b-button size="sm" variant="danger" @click="cancel()">
                                      {{$t('general.buttons.cancel')}}
                              </b-button>
                              <b-button size="sm" variant="success" @click="acceptDiscountSelling()" :disabled="checkDiscountButton()">
                              {{$t('detailPanel.discountModal.confirmButton')}}
                              </b-button>
                      </template>
                  </b-modal>
  
                  <History ref="history" :selectedWine="selectedWine" />
              </div>
  
              <div class="venueWineDetailContainer">
                  <div class="card-header">
                    <div class="editButtons">
                    <b-dropdown size="lg" no-caret>
                    <template #button-content>
                    <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item-button class="edit" @click="switchToEditMode">{{$t('general.buttons.edit')}}</b-dropdown-item-button>
                    <b-dropdown-item-button class="delete" @click="deleteWine()">{{$t('general.buttons.delete')}}</b-dropdown-item-button>
                    <b-dropdown-item-button class="move" v-b-modal.modal-moveWine :disabled="checkButton(true)" @click="wineMoveAmount = selectedWine.attributes.amount" >{{$t('detailPanel.moveWines')}}</b-dropdown-item-button>
                    <b-dropdown-item-button class="reduce" v-b-modal.modal-reduceWineAmount :disabled="checkButton(true)" @click="wineMoveAmount = selectedWine.attributes.amount">{{$t('detailPanel.reduceBottles')}}</b-dropdown-item-button>
                    <b-dropdown-item-button class="discount" v-b-modal.modal-sellWithDiscount :disabled="checkButton(true)" @click="resetDiscountProperties(); setFocus()">{{$t('detailPanel.sellWithDiscount')}}</b-dropdown-item-button>
                    <b-dropdown-item-button class="rarity" @click="markAsRarity();">{{this.selectedWine.attributes.rarity? $t('detailPanel.unmarkAsRarity') : $t('detailPanel.markAsRarity')}}</b-dropdown-item-button>
                    </b-dropdown>
                    </div>
                    <div v-if="selectedWine.attributes.amount !== tempselectedWine.attributes.amount" class="editModeOverlay"></div>
                    <div class="wineInfo">
                        <b-row class="mb-3">
                            <b-col class="smallText" cols="3">{{$t('general.vintage')}}</b-col>
                            <b-col cols="3">{{ selectedWine.attributes.year ? selectedWine.attributes.year: $t('general.notDefined') }}</b-col>
                            <b-col class="smallText" cols="3">{{$t('general.country')}}</b-col>
                            <b-col cols="3" v-if="selectedWine.attributes.wine.data.attributes.land">{{  $t('countries.'+ selectedWine.attributes.wine.data.attributes.land.data.attributes.localeKey) }}</b-col>
                        </b-row>
                        <b-row class="mb-3">
                            <b-col class="smallText" cols="3">{{$t('general.bottleSize')}}</b-col>
                            <b-col cols="3" v-if="selectedWine.attributes.bottleSize != null">{{ selectedWine.attributes.bottleSize.data.attributes.title }}</b-col>
                            <b-col class="smallText"  cols="3">{{$t('general.region')}}</b-col>
                            <b-col cols="3" v-if="selectedWine.attributes.wine.data.attributes.region">{{ selectedWine.attributes.wine.data.attributes.region.data.attributes.localeKey ? $t('regions.' + selectedWine.attributes.wine.data.attributes.region.data.attributes.localeKey) : selectedWine.attributes.wine.data.attributes.region.data.attributes.title }}</b-col>
                        </b-row>
                         <b-row class="mb-3">
                            <b-col class="smallText" cols="3">{{$t('general.color')}}</b-col>
                            <b-col cols="3" v-if="selectedWine.attributes.wine.data.attributes.wineType">{{ selectedWine.attributes.wine.data.attributes.wineType.data.attributes.title }}</b-col>
                            <b-col class="smallText" cols="3">{{$t('general.winetype')}}</b-col>
                            <b-col cols="3" v-if="selectedWine.attributes.wine.data.attributes.grapeVariety">{{ selectedWine.attributes.wine.data.attributes.grapeVariety.data.attributes.title }}</b-col>
                         </b-row>
                  </div>
                  </div>
                      <h2>{{$t('detailPanel.yourInformation')}}</h2>
                      <div v-if="!wineDetailLoading && transitionFinished" class="box">
                        <b-row>
                          <b-col cols="6">{{$t('general.shelf')}}</b-col>
                          <b-col cols="6">{{$t('general.articleNumber')}}</b-col>
                        </b-row>
                        <b-row class="mb-3">
                            <b-col cols="6"><span v-if="selectedWine.attributes.shelfNumber" class="smallText">{{ selectedWine.attributes.shelfNumber }}</span></b-col>
                            <b-col cols="6"><span v-if="selectedWine.attributes.articleNumber" class="smallText">{{ selectedWine.attributes.articleNumber }}</span></b-col>
                        </b-row>
                        <PurchasePrices  :venueSupplierOptions="venueSupplierOptions" :purchasePrices="filterDeletedPurchasePrices" :currency="getCurrency" />
                        <SellingPrices :selectedWine="selectedWine" :currency="getCurrency" />
                        <b-row>
                            <b-col cols="6">
                                {{ $t('general.weq') }}<br/>
                                <Weq :purchasePriceAvg="selectedWine.attributes.purchasePriceAvg" :sellingPrice="selectedWine.attributes.sellingPrice"/>
                            </b-col>
                            <b-col cols="6">
                                {{ $t('general.netprofit') }}<br/>
                                <NetProfit :purchasePriceAvg="selectedWine.attributes.purchasePriceAvg" :sellingPrice="selectedWine.attributes.sellingPrice" :currceny="getCurrency"/>
                            </b-col>
                        </b-row>
                        <Ratings :ratings="filterDeletedRatings"/>
                        <div class="spacer s"/>
                          <b-row>
                              <b-col>
                                  <h3>{{$t('general.tastingNote')}}</h3>
                                  <textarea  disabled v-model="selectedWine.attributes.comments">
                                  </textarea>
                              </b-col>
                          </b-row>
                      </div>
                      <div v-else class="box">
                        <LoadingAnimation/>
                      </div>
                  
              </div>
      </b-row>
    </div>
      <b-modal size="md" v-model="showGlassError" :title="$t('detailPanel.glasReduceErrorModal.title')">
        <div class="row">
            <b-col>
                <p>{{$t('detailPanel.glasReduceErrorModal.text')}}</p>
            </b-col>   
        </div>
        <template #modal-footer="{}">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="danger" @click="showGlassError=false; selectedWine.attributes.glassMode = true">
                {{$t('general.buttons.no')}}
            </b-button>
            <b-button size="sm" variant="success" @click="deactivateGlassMode()">
                {{$t('general.buttons.yes')}}
            </b-button>
        </template>
      </b-modal>
  
    </div>
  </template>
  
  <script>
    import * as Utils from '@/utils/functions'
    import DataService from '@/services/StrapiService'
    import { mapGetters } from 'vuex'
    import { getVenueCurrency } from '@/utils/functions'
    import PurchasePrices from './wineDetail/PurchasePrices.vue'
    import PurchasePricesEdit from './wineDetail/PurchasePricesEdit.vue'
    import Ratings from './wineDetail/Ratings.vue'
    import SellingPrices from './wineDetail/SellingPrices.vue'
    import Weq from './wineDetail/Weq.vue'
    import NetProfit from './wineDetail/NetProfit.vue'
    import History from './wineDetail/History.vue'
    import RatingsEdit from './wineDetail/RatingsEdit.vue'
    import SellingPricesEdit from './wineDetail/SellingPricesEdit.vue'
    import YearDropdown from './YearDropdown.vue'
    import LoadingAnimation from '@/components/LoadingAnimation.vue'
    import CauseOfReduction from './wineDetail/CauseOfReduction.vue'
  
    export default {
      name: "WineDetailOverlay",
      props: {
          detailsClass: String,
          closePressed: Function,
          selectedWineFromParent: Object,
          reloadWinesList: Function,
      },
      model: {
          prop: "selectedWineFromParent",
          event: "selectedWineChange"
      },
      created() {
        this.loadVenuesOfUser();  
        if (this.playlistId == null && this.smartListId == null) {
            this.$store.commit("updateFilter", {});
        }
        this.venueSupplierOptions = Utils.loadVenueSupplierOptions(this.getVenue)
      },
      mounted() {
        this.$refs.wineDetailSidebar.addEventListener('transitionend', () => {       
          this.transitionFinishedFunction()
        })
        // OLD Safari Listener:
        this.$refs.wineDetailSidebar.addEventListener('webkitTransitionEnd', () => {     
          this.transitionFinishedFunction()     
        })
        this.$root.$on('bv::modal::show', (modalId) => {
            if  (modalId === 'modal-reduceWineAmount') {
                this.selectedCauseOfReduction = null;
            }
        }) /* bootstrap-vue reset modal before show */
      }, 
      data() {
          return {
              venues: [],
              venueSupplierOptions: [],
              tempselectedWine: {},
              selectedWineWinery: {},
              wineEditMode: false,
              venuesForWineMove: [],
              strapiUrl: DataService.getServerUrl(),
              nowEditingBottles: false,
              nowEditingGlasses: false,
              tempReducedGlasses: 0,
              tempReducedBottles: 0,
              tempReducedOpenBottles: 0,
              wineMoveAmount: 0,
              movementText: this.$t("general.dropdownText"),
              selectedVenueForWineMovement: null,
              showGlassError: false,
              wineDiscountQuantity: 1,
              discountAmountPerBottle: 0,
              discount: 0,
              discountPercentage: 0,
              discountPriceTotal: 0,
              amountIsDirectlySet: false,
              wineDetailLoading: true,
              transitionFinished: false,
              wineDetailRatings: {},
              wineDetailPurchasePrices: {},
              overlayIsVisible: false,
              tempPurchasePricesAvg: 0,
              selectedCauseOfReduction: null,
              tempSellingPrice: 0,
          };
      },
      methods: {
          setPurchasePricesAvg(purchasePricesAvg) {
            this.tempPurchasePricesAvg = purchasePricesAvg;
          },
          setTempSellingPrice(tempSellingPrice) {
            this.tempSellingPrice = tempSellingPrice;
          },
          markAsRarity() {
              this.selectedWine.attributes.rarity = !this.selectedWine.attributes.rarity;
              this.updateVenueWine();
          },
          deleteWine() {
              this.selectedWine.attributes.deleted = true;
              this.updateVenueWine();
          },
          /**
           * Check if Amount Controls shoud be disabled
           */
          disableAmountControls() {
              var shouldDisalbe = false;
              if (this.nowEditingGlasses || !this.selectedWine.attributes.sellingPrice) {
                  shouldDisalbe = true;
              }
              return shouldDisalbe;
          },
          async loadVenuesOfUser() {
              await DataService.getVenues().then((response) => {
                  this.venues = response["data"].data;
                  for (var i = 0; i < this.venues.length; i++) {
                      var venueObject = {
                          value: this.venues[i].id,
                          text: this.venues[i].attributes.title
                      };
                      if (this.venues[i].id != this.getVenue.id) {
                          this.venuesForWineMove.push(venueObject);
                      }
                  }
              }).catch((err) => {
                  console.log(err);
              });
          },
          toggleFavorite() {
              this.selectedWine.attributes.favorite = !this.selectedWine.attributes.favorite;
              this.updateVenueWine();
          },
          switchToEditMode() {
                this.tempPurchasePricesAvg = this.selectedWine.attributes.purchasePriceAvg
                this.tempSellingPrice = this.selectedWine.attributes.sellingPrice
                this.wineEditMode = !this.wineEditMode;
          },
          changeMoveWineAmmount(change) {
              if (this.wineMoveAmount + change >= 0 && this.wineMoveAmount + change <= this.selectedWine.attributes.amount) {
                  this.wineMoveAmount = this.wineMoveAmount + change;
              }
          },
          async lockAmount(accept) {
              if (accept === true) {
                  // Update Venue Wine
                  await this.updateVenueWine();
              }
              else {
                  this.selectedWine = JSON.parse(JSON.stringify(this.tempselectedWine));
                  await this.reloadWinesList();
              }
              this.nowEditingBottles = false;
              this.nowEditingGlasses = false;
              this.showLockButtons = false;
              this.tempReducedGlasses = 0;
              this.tempReducedBottles = 0;
              this.tempReducedOpenBottles = 0;
              this.amountIsDirectlySet = false;
          },
          updateTempReducedBottles() {
            this.amountIsDirectlySet = true;
            this.nowEditingBottles = true;
            this.tempReducedBottles =  this.tempselectedWine.attributes.amount - this.selectedWine.attributes.amount
          },
          changeBottleAmount(operator) {
              this.nowEditingBottles = true;
              if (operator === "add") {
                  this.selectedWine.attributes.amount += 1;
                  this.tempReducedBottles -= 1;
                    if (this.tempReducedOpenBottles > 0) {
                        this.tempReducedOpenBottles -= 1;
                    }
              }
              if (operator === "reduce") {
                  if (this.selectedWine.attributes.amount >= 1) {
                      this.selectedWine.attributes.amount -= 1;
                      this.tempReducedBottles += 1;
                  }
              }
          },
          changeGlassAmount(operator) {
              this.nowEditingGlasses = true;
              if (operator === "add") {
                  if (this.selectedWine.attributes.amountGlasses < this.calcGlassAmountForBottleSize()) {
                      this.selectedWine.attributes.amountGlasses += 1;
                      if (this.tempReducedGlasses > 0) {
                          this.tempReducedGlasses -= 1;
                      }
                  }
                  if (this.selectedWine.attributes.amountGlasses > 0) {
                      this.selectedWine.attributes["openNewBottle"] = false;
                  }
              }
              if (operator === "reduce") {
                  if (this.selectedWine.attributes.amountGlasses >= 1) {
                      this.selectedWine.attributes.amountGlasses -= 1;
                      this.tempReducedGlasses += 1;
                      if (this.selectedWine.attributes.amountGlasses == 0) {
                        if (this.selectedWine.attributes.amount > 0) {
                          this.selectedWine.attributes.amount -= 1;
                          this.tempReducedOpenBottles += 1;
                          this.selectedWine.attributes["openNewBottle"] = true;
                          this.selectedWine.attributes.amountGlasses = this.calcGlassAmountForBottleSize();
                        }
                      }
                  }
              }
          },
          changeGlassMode() {
              if (this.selectedWine.attributes.glassMode == false) {
                  if (this.selectedWine.attributes.amountGlasses > 0) {
                      this.showGlassError = true;
                  } else {
                    this.selectedWine.attributes.glassMode = false
                    this.lockAmount(true)
                  }
              }
              else {
                  if (this.selectedWine.attributes.amount > 0) {
                      this.nowEditingBottles = true;
                      this.selectedWine.attributes.amount -= 1;
                      this.tempReducedOpenBottles += 1;
                      this.selectedWine.attributes.amountGlasses = this.calcGlassAmountForBottleSize();
                  }
                  else {
                      this.selectedWine.attributes.glassMode = false;
                      alert(this.$t("detailPanel.glaswiseErrorMessage"));
                  }
              }
          },
          calcGlassAmountForBottleSize() {
              if (this.selectedWine.attributes.bottleSize.data.attributes != null
                  && this.getVenue.attributes.glassAmountPerBottle != null
                  && this.getVenue.attributes.glassAmountPerBottle.length > 0) {
                  for (var i = 0; i < this.getVenue.attributes.glassAmountPerBottle.length; i++) {
                      if (this.getVenue.attributes.glassAmountPerBottle[i].bottleSizeId == this.selectedWine.attributes.bottleSize.data.id) {
                          return this.getVenue.attributes.glassAmountPerBottle[i].amount;
                      }
                  }
                  return 5;
              }
              else {
                  return 4;
              }
          },
          deactivateGlassMode() {
              this.showGlassError = false;
              this.selectedWine.attributes.glassMode = false;
              this.selectedWine.attributes.amountGlasses = 0;
              this.lockAmount(true);
          },
          async updateVenueWine() {
              this.wineEditMode = false;
  
              //store selected wine state in tempselectedWine
              this.tempselectedWine = Utils.deepCopyObject(this.selectedWine)
              
                          this.getEditedDataFromComponents()
  
                          //validate and prepare inputs
                          this.validateAndPrepareSellingPrices()
                          this.validateAndPreparePurchasePrices()
                          this.validateAndPrepareRatings()
  
                          //update glass amount for history when new bottle will be opened
              const success = await this.updateVenueWineGlassesForOpenedBottle();
              if (!success) {
                  //don't update venue wine if something went wrong earlier
                  return;
              }
              //update all VenueWine Data
              DataService.updateVenueWine(this.selectedWine.id, this.selectedWine).then((result) => {
                  this.selectedWine.attributes = JSON.parse(JSON.stringify(result["data"].data.attributes));
                  this.loadExtraWineDetailsInfos()
                  this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                  // update variable in parent (VenueWines List)
                  this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.selectedWine)));
                  if (this.selectedWine.attributes.deleted == true) {
                      var deleteSuccessAlert = {
                          text: this.$t("detailPanel.messages.deleteSuccess"),
                          variant: "info"
                      };
                      this.$store.commit("setAlertText", deleteSuccessAlert);
                      this.closePressed();
                  }
                  else {
                      var alertobject = {
                          text: this.$t("detailPanel.messages.saveSuccess"),
                          variant: "info"
                      };
                      this.$store.commit("setAlertText", alertobject);
                  }
                  this.wineMoveAmount = this.selectedWine.attributes.amount;
              }).catch((err) => {
                  // handle error
                  var alertobject = {
                      text: this.$t("detailPanel.messages.saveError"),
                      variant: "danger"
                  };
                  this.$store.commit("setAlertText", alertobject);
                  console.log(err);
              }).then(() => {
                  delete this.selectedWine.attributes.openNewBottle;
                  delete this.tempselectedWine.attributes.openNewBottle;
                  this.refreshWineHistory();
                  //reload wine list so that changes in selected wine are applied to wine in list
                  //this.reloadWinesList();
              });
          },
                  /**
                   * Get Edited Data from Components if user edited them otherwise let selectedWine attributes untouched
                   */
                  getEditedDataFromComponents() {
                      if (this.$refs.sellingPricesEdit) {
                          this.selectedWine.attributes.sellingPrice = Utils.deepCopyObject(this.$refs.sellingPricesEdit.$data.sellingPrice)
                          this.selectedWine.attributes.glassPrice = Utils.deepCopyObject(this.$refs.sellingPricesEdit.$data.glassPrice)
                      }
                      if (this.$refs.purchasePricesEdit) {
                          this.selectedWine.attributes.purchasePrices = Utils.deepCopyObject(this.$refs.purchasePricesEdit.$data.purchasePrices)
                      }
                      if (this.$refs.ratingsEdit) {
                          this.selectedWine.attributes.ratings = Utils.deepCopyObject(this.$refs.ratingsEdit.$data.ratings)
                      }
                  },
                  validateAndPrepareSellingPrices() {
                      if (this.selectedWine.attributes.sellingPrice === "" || this.selectedWine.attributes.sellingPrice === null || this.selectedWine.attributes.sellingPrice < 0) {
                          this.selectedWine.attributes.sellingPrice = 0;
                      }
                      if (this.selectedWine.attributes.glassPrice === "" || this.selectedWine.attributes.glassPrice === null || this.selectedWine.attributes.glassPrice < 0) {
                          this.selectedWine.attributes.glassPrice = 0;
                      }
                  },
                  validateAndPreparePurchasePrices() {
                      if (this.selectedWine.attributes.purchasePrices != null && this.selectedWine.attributes.purchasePrices.length > 0) {
                              var updatedPurchasePrices = [];
                              for (var key in this.selectedWine.attributes.purchasePrices) {
                                      var purchasePrice = this.selectedWine.attributes.purchasePrices[key];
                                      if (!Utils.shouldDeletePurchasePrice(purchasePrice) && purchasePrice.wineSupplier.data) {
                                              var updatedPurchasePrice = {
                                                      price: purchasePrice.price,
                                                      wineSupplier: purchasePrice.wineSupplier.data.id,
                                              };
                                              if (updatedPurchasePrice.price === "" || updatedPurchasePrice.price === null || updatedPurchasePrice.price < 0) {
                                                      updatedPurchasePrice.price = 0;
                                              }
                                              if (!("createNew" in purchasePrice) && "id" in purchasePrice) {
                                                      //take id from already saved purchasePrice
                                                      updatedPurchasePrice["id"] = purchasePrice.id;
                                              }
                                              updatedPurchasePrices.push(updatedPurchasePrice);
                                      }
                              }
                              this.selectedWine.attributes.purchasePrices = updatedPurchasePrices;
                      }
                  },
                  validateAndPrepareRatings() {
                      if (this.selectedWine.attributes.ratings != null && this.selectedWine.attributes.ratings.length > 0) {
                          var updatedRatings = [];
                          for (var ratingKey in this.selectedWine.attributes.ratings) {
                                  var rating = this.selectedWine.attributes.ratings[ratingKey];
                                  if (!Utils.shouldDeleteRating(rating) && rating.ratingAgency.data) {
                                          var updatedRating = {
                                                  value: rating.value,
                                                  ratingAgency: rating.ratingAgency.data.id,
                                          };
                                          if (updatedRating.value === "" || updatedRating.value === null || (updatedRating.value < rating.ratingAgency.data.attributes.min && updatedRating.value > rating.ratingAgency.data.attributes.max)) {
                                                  updatedRating.value = rating.ratingAgency.data.attributes.min;
                                          }
                                          if (!("createNew" in rating) && "id" in rating) {
                                                  //take id from already saved purchasePrice
                                                  updatedRating["id"] = rating.id;
                                          }
                                          updatedRatings.push(updatedRating);
                                  }
                          }
                          this.selectedWine.attributes.ratings = updatedRatings;
                      }
                  },
                  async updateVenueWineGlassesForOpenedBottle() {
              //when new bottle in glass mode will be opened
              if ("openNewBottle" in this.selectedWine.attributes && this.selectedWine.attributes.openNewBottle === true) {
                  //only update the glasses that have been reduced 
                  const reducedGlassesData = {
                      attributes: {
                          amountGlasses: this.tempReducedGlasses * -1,
                          openNewBottle: true
                      }
                  };
                  await DataService.updateVenueWine(this.selectedWine.id, reducedGlassesData).then(() => {
                      return true;
                  }).catch((err) => {
                      this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.tempselectedWine))); // error rollback variable in parent (VenueWines List)
                      // handle error
                      var alertobject = {
                          text: this.$t("detailPanel.messages.saveError"),
                          variant: "danger"
                      };
                      this.$store.commit("setAlertText", alertobject);
                      console.log(err);
                      return false;
                  });
              }
              return true;
          },
          async acceptWineMovement() {
              const reducedSelectedWine = {
                  attributes: {
                      amount: this.selectedWine.attributes.amount -= this.wineMoveAmount,
                      isMoving: true,
                      newVenue: this.selectedVenueForWineMovement
                  }
              };
              this.wineEditMode = false;
              this.amountIsDirectlySet = false;
              this.$bvModal.hide("modal-moveWine");
              let response = await DataService.findVenueWineInVenueId(this.selectedWine.attributes.wine.data.id, this.selectedWine.attributes.year, this.selectedWine.attributes.bottleSize.data.id, this.selectedVenueForWineMovement);
              //venueWine already exists in other venue
              if (response["data"].data != null && response["data"].data.length > 0) {
                  var venueWineFromStrapi = response["data"].data[0];
                  const venueWineFromStrapiData = {
                      attributes: {
                          amount: venueWineFromStrapi.attributes.amount += parseInt(this.wineMoveAmount)
                      }
                  };
                  console.log("wineAmountNew",venueWineFromStrapiData.attributes.amount)
                  DataService.updateVenueWine(venueWineFromStrapi.id, venueWineFromStrapiData).then(() => {
                      DataService.updateVenueWine(this.selectedWine.id, reducedSelectedWine).then((result) => {
                          this.selectedWine.attributes = JSON.parse(JSON.stringify(result["data"].data.attributes));
                          this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                          // update variable in parent (VenueWines List)
                          this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.selectedWine)));
                          var alertobject = {
                              text: this.$t("detailPanel.messages.movedWine"),
                              variant: "info"
                          };
                          this.$store.commit("setAlertText", alertobject);
                      }).catch((err) => {
                          var alertobject = {
                              text: this.$t("detailPanel.messages.editWineError"),
                              variant: "danger"
                          };
                          this.$store.commit("setAlertText", alertobject);
                          console.log(err);
                      }).then(() => {
                          // always executed after request: clear newVenue after movement
                          this.selectedVenueForWineMovement = null;
                          this.selectedWine.attributes["newVenue"] = null;
                          this.tempselectedWine.attributes["newVenue"] = null;
                          delete this.selectedWine.attributes.isMoving;
                          delete this.tempselectedWine.attributes.isMoving;
                          this.refreshWineHistory();
                          this.reloadWinesList();
                      });
                  }).catch((err) => {
                      // handle error
                      var alertobject = {
                          text: this.$t("detailPanel.messages.editExistingWineError"),
                          variant: "danger"
                      };
                      this.$store.commit("setAlertText", alertobject);
                      console.log(err);
                  });
              }
              else {
                  //venueWine does not exists in other venue and needs to be created
                  var newVenueVineForMovement = {};
                  newVenueVineForMovement.attributes = JSON.parse(JSON.stringify(this.selectedWine.attributes));
                  newVenueVineForMovement.attributes.venue = this.selectedVenueForWineMovement;
                  newVenueVineForMovement.attributes.amount = this.wineMoveAmount;
                  newVenueVineForMovement.attributes.wine = this.selectedWine.attributes.wine.data.id;
                  newVenueVineForMovement.attributes.bottleSize = this.selectedWine.attributes.bottleSize.data.id;
                  if ("purchasePrices" in newVenueVineForMovement.attributes) {
                      for await (var purchasePrice of newVenueVineForMovement.attributes.purchasePrices) {
                          delete purchasePrice.id;
                          purchasePrice.wineSupplier = purchasePrice.wineSupplier.data.id;
                          //get wineSupplier with all related venues and update venue relation of wineSupplier
                          DataService.getWineSupplier(purchasePrice.wineSupplier).then((response) => {
                              const wineSupplier = response["data"].data;
                              const wineSupplierBody = {
                                  attributes: {
                                      venues: {
                                          data: [
                                              ...wineSupplier.attributes.venues.data,
                                              { id: this.selectedVenueForWineMovement }
                                          ]
                                      }
                                  }
                              };
                              DataService.updateWineSupplier(purchasePrice.wineSupplier, wineSupplierBody);
                          });
                      }
                  }
                  DataService.createVenueWine(newVenueVineForMovement).then(() => {
                      DataService.updateVenueWine(this.selectedWine.id, reducedSelectedWine).then((result) => {
                          this.selectedWine.attributes = JSON.parse(JSON.stringify(result["data"].data.attributes));
                          this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                          // update variable in parent (VenueWines List)
                          this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.selectedWine)));
                          var alertobject = {
                              text: this.$t("detailPanel.messages.movedWine"),
                              variant: "info"
                          };
                          this.$store.commit("setAlertText", alertobject);
                      }).catch((err) => {
                          var alertobject = {
                              text: this.$t("detailPanel.messages.editWineError"),
                              variant: "danger"
                          };
                          this.$store.commit("setAlertText", alertobject);
                          // handle error
                          console.log(err);
                      }).then(() => {
                          // always executed after request: clear newVenue after movement
                          this.selectedVenueForWineMovement = null;
                          this.tempselectedWine.attributes["newVenue"] = null;
                          delete this.selectedWine.attributes.isMoving;
                          delete this.tempselectedWine.attributes.isMoving;
                          this.refreshWineHistory();
                          this.reloadWinesList();
                      });
                  }).catch((err) => {
                      // handle error
                      var alertobject = {
                          text: this.$t("detailPanel.messages.addWineError"),
                          variant: "danger"
                      };
                      this.$store.commit("setAlertText", alertobject);
                      console.log(err);
                  });
              }
          },
                  refreshWineHistory() {
            this.$nextTick(() => {
              if (this.$refs.history) {
                this.$refs.history.loadWineHistory()
              }
            })
        },
        declineWinereducing() {
            this.$bvModal.hide("modal-reduceWineAmount");
            this.amountIsDirectlySet = false;
        },
          acceptWineReducing() {
            this.$bvModal.hide("modal-reduceWineAmount");
            this.amountIsDirectlySet = false;
              const reducedData = {
                  attributes: {
                      amount: this.selectedWine.attributes.amount - this.wineMoveAmount,
                      noRevenue: true,
                      causeOfReduction: this.selectedCauseOfReduction
                  }
              };
              DataService.updateVenueWine(this.selectedWine.id, reducedData).then((result) => {
                  this.selectedWine.attributes = result["data"].data.attributes;
                  this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                  // update variable in parent (VenueWines List)
                  this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.selectedWine)));
                  var alertobject = {
                      text: "Wein erfolgreich reduziert",
                      variant: "info"
                  };
                  this.$store.commit("setAlertText", alertobject);
                  this.refreshWineHistory();
              }).catch((err) => {
                  var alertobject = {
                      text: this.$t("detailPanel.messages.editWineError"),
                      variant: "danger"
                  };
                  this.$store.commit("setAlertText", alertobject);
                  // handle error
                  console.log(err);
              });
          },
          changeDiscountAmmount(change) {
              if (this.wineDiscountQuantity + change >= 0 && this.wineDiscountQuantity + change <= this.selectedWine.attributes.amount) {
                  this.wineDiscountQuantity = this.wineDiscountQuantity + change;
              }
              this.calculateDiscount();
          },
          acceptDiscountSelling() {
              this.$bvModal.hide("modal-sellWithDiscount");
              const discountData = {
                  attributes: {
                      amount: this.selectedWine.attributes.amount - this.wineDiscountQuantity,
                      discountPercentage: this.discountPercentage,
                      discountSellingPrice: this.selectedWine.attributes.discountSellingPrice,
                      discount: true,
                  }
              };
              DataService.updateVenueWine(this.selectedWine.id, discountData).then((result) => {
                  this.selectedWine.attributes = result["data"].data.attributes;
                  this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                  // update variable in parent (VenueWines List)
                  this.$emit("update:selectedWine", JSON.parse(JSON.stringify(this.selectedWine)));
                  var alertobject = {
                      text: this.$t("detailPanel.discountModal.successMessage"),
                      variant: "info"
                  };
                  this.$store.commit("setAlertText", alertobject);
                  this.refreshWineHistory();
              }).catch((err) => {
                  var alertobject = {
                      text: this.$t("detailPanel.messages.editWineError"),
                      variant: "danger"
                  };
                  this.$store.commit("setAlertText", alertobject);
                  // handle error
                  console.log(err);
              }).then(() => {
                  delete this.selectedWine.attributes.discount;
                  delete this.selectedWine.attributes.discountPercentage;
                  delete this.selectedWine.attributes.discountSellingPrice;
              });
          },
          calculateDiscount() {
              if (this.discount <= this.selectedWine.attributes.sellingPrice) {
                  this.selectedWine.attributes.discountSellingPrice = this.selectedWine.attributes.sellingPrice - this.discount;
              }
              else {
                  return;
              }
              this.discountPercentage = Math.round(100 / this.selectedWine.attributes.sellingPrice * this.discount);
              this.discountPriceTotal = this.wineDiscountQuantity * this.selectedWine.attributes.discountSellingPrice;
          },
          resetDiscountProperties() {
              this.wineDiscountQuantity = 1;
              this.discount = "";
              this.discountPercentage = 0;
              this.selectedWine.attributes.discountSellingPrice = 0;
              this.discountPriceTotal = 0;
          },
          checkButton(amountOnly) {
              if (amountOnly === true) {
                  if (this.selectedWine.attributes.amount <= 0) {
                      return true;
                  }
                  else {
                      return false;
                  }
              }
              else {
                  if (this.selectedWine.attributes.amount <= 0 || this.selectedWine.attributes.sellingPrice <= 0) {
                      return true;
                  }
                  else {
                      return false;
                  }
              }
          },
          checkDiscountButton() {
              if (this.wineDiscountQuantity <= 0 ||
                  this.discount <= 0 ||
                  this.wineDiscountQuantity < 1 ||
                  this.discount >= this.selectedWine.attributes.sellingPrice) {
                  return true;
              }
              else {
                  return false;
              }
          },
          setFocus() {
              setTimeout(() => {
                  this.$nextTick(() => this.$refs.inputFocus.focus());
              }, 100);
          },
          async cancelEditVenueWine() {
              this.wineEditMode = false;
              this.selectedWine.attributes = JSON.parse(JSON.stringify(this.tempselectedWine.attributes));
              await this.reloadWinesList();
          },
          changeYear(year) {
            this.selectedWine.attributes.year = year
          },
          loadExtraWineDetailsInfos() {
            this.wineDetailLoading = true
            DataService.getVenueWineDetail(this.selectedWine.id).then((result) => {
              this.wineDetailPurchasePrices = result["data"].data.attributes.purchasePrices
              this.wineDetailRatings = result["data"].data.attributes.ratings
              this.wineDetailLoading = false
              if (this.transitionFinished === true) {
                this.showExtraInfos ()
              }
            })
          },
          showExtraInfos () {
            this.$set(this.selectedWine.attributes, "purchasePrices", this.wineDetailPurchasePrices);
            this.$set(this.selectedWine.attributes, "ratings",  this.wineDetailRatings);
          },
          transitionFinishedFunction() {
            if (this.overlayIsVisible) {
              this.transitionFinished = true
              if (this.wineDetailLoading == false) {
                this.showExtraInfos ()
              }
            }
          }
      },
      computed: {
          ...mapGetters(["getVenue", "getClickedOutside", "getReloadWineSuppliers"]),
          getCurrency() {
            return getVenueCurrency(this.getVenue);
          },
          filterDeletedPurchasePrices() {
            return Utils.getNotDeletedPurchasePrices(this.selectedWine.attributes.purchasePrices);
          },
          filterDeletedRatings() {
            return Utils.getNotDeletedRatings(this.selectedWine.attributes.ratings);
          },
          displayTempReducedBottles() {
            return Math.abs(this.tempReducedBottles) // format negative number to positive -2 bottles means 2 bottles are added
          },
          selectedWine: {
            get: function () {
              return this.selectedWineFromParent;
            },
            set: function (value) {
              this.$emit("selectedWineChange", value);
            }
          },
      },
      watch: {
          /**
           * Called every time detailsClass changes to active
           * -> when venue details side bar opens
                   *
           * @param {*} newVal
           * @param {*} oldVal
           */
          detailsClass(newVal, oldVal) {
              if (newVal !== oldVal && newVal == "active") {
                this.overlayIsVisible = true
                this.loadExtraWineDetailsInfos()
                // show extra infos after slide in transition has finished:
                this.tempselectedWine = JSON.parse(JSON.stringify(this.selectedWine));
                this.selectedWine.attributes.wineId = this.selectedWine.attributes.wine.data.id;
                this.wineBottleAmount = this.selectedWine.attributes.amount;
                this.wineMoveAmount = this.selectedWine.attributes.amount;
                DataService.getWinery(this.selectedWine.attributes.wine.data.attributes.winery.data.id).then((response) => {
                  this.selectedWineWinery = response["data"].data;
                }).catch((err) => {
                    // handle error
                    this.selectedWineWinery = {};
                    console.log(err);
                });
                this.refreshWineHistory();
              }
              else if (newVal !== oldVal && newVal == "") {
                if (this.wineEditMode ) {
                  this.cancelEditVenueWine();
                }
                this.nowEditingBottles = false;
                this.nowEditingGlasses = false;
                this.showLockButtons = false;
                this.tempReducedGlasses = 0;
                this.tempReducedBottles = 0;
                this.tempReducedOpenBottles = 0;
                this.transitionFinished = false
                this.overlayIsVisible = false
                this.wineDetailLoading = true
              }
          },
          getReloadWineSuppliers() {
              Utils.loadVenueSupplierOptions(this.getVenue);
          },

      },
      components: { Ratings, SellingPrices, PurchasePrices, PurchasePricesEdit, History, RatingsEdit, SellingPricesEdit, YearDropdown, Weq, NetProfit, LoadingAnimation, CauseOfReduction }
  }
  </script>