<template>
	<div class="add-winery-row">
		<div >
			<b-row>
				<b-col cols="12" class="text-center">
					<LoadingAnimation v-if="loading" />
				</b-col>
			</b-row>
		</div>
		
		<div v-if="loading == false">
			<button v-if="inputIsHidden" @click="toggleInput()" class="iconButton add">{{ $t('winelist.addWineModal.addWineryRow') }}</button>
			<b-row v-else>
				<b-col class="col-px-width-24">
					<button @click="toggleInput()" class="inline-row-button cancel-button"></button>
				</b-col>
				<b-col>
					<b-form-input ref="wineryName" v-model="wineryName" :placeholder="$t('winelist.addWineModal.wineryPlaceholder')"></b-form-input>
				</b-col>
				<b-col>
					<SearchDataList ref="landSearch" :autoSuggestComplete="landOptions" :isLastSearch="false" :itemClickedFunction="handleLandSelected.bind(selectedLand)" :placeholder="$t('winelist.addWineModal.landPlaceholder')"/>
				</b-col>
				<b-col>
					<SearchDataList ref="regionSearch" :autoSuggestComplete="regionOptions" :isLastSearch="false" :itemClickedFunction="handleRegionSelected.bind(selectedRegion)" :placeholder="$t('winelist.addWineModal.regionPlaceholder')" :disabled="checkIfCountryIsSelected()"/>
				</b-col>
				<b-col class="col-px-width-24">
					<button @click="addWinery()" class="inlineRow accept"></button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import DataService from '@/services/StrapiService'
import DataMappingService from '@/services/DataMappingService'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import Winery from '@/model/Winery.js'
import SearchDataList from '@/components/SearchDataList.vue'

export default {
	name: 'AddWineryRow',
	components: {
		LoadingAnimation,
		SearchDataList
	},
	emits: ['wineryAdded'],
	data() {
		return {
			loading: false,
			inputIsHidden: true,
			landOptions: [],
			selectedRegion: {
				text: this.$t('general.dropdownText'),
				value: null
			},
			selectedLand: {
				text: this.$t('general.dropdownText'),
				value: null
			},
			wineryName: '',
		}
	},
	mounted() {
		this.getLandOptions()
	},
	methods: {
		getLandOptions() {
			this.showLoading()
			DataService.getAllLands('title').then((response) => {
					this.hideLoading()
					this.landOptions = DataMappingService.mapGetAllLandsResponseToOptions(response)   
        }).catch(() => {
					this.hideLoading()
					this.showErrorAlert(this.$t('errors.loadingError'))
        })
		},
		handleLandSelected(land) {
			this.selectedLand = land
		},
		handleRegionSelected(region) {
			this.selectedRegion = region
		},
		async addWinery() {
			const winery = new Winery()
			winery.title = this.wineryName.trim()
			winery.region.id = this.selectedRegion.value
			winery.land.id = this.selectedLand.value

			if (this.validateWinery(winery) === false) {
				this.showErrorAlert(this.$t('errors.savingError'))
				return
			}
			const canCreateNewWine = await this.findDuplicateWinery(winery)
			if (canCreateNewWine === true) {
				this.sendCreateWineryRequest(winery)
			} else {
				this.showErrorAlert(this.$t('winelist.addWineModal.wineryAlreadyExists'))
			}
		},
		/**
		 * Check if winery is valid with all necessary fields for strapi
		 * @param {} winery 
		 */
		validateWinery(winery) {
			return winery.title.length > 0 && winery.region.id != null && winery.land.id != null
		},
		/**
		 * Check if winery already exists in db
		 * returns true if new winery can be created
		 * @param {} winery 
		 */
		async findDuplicateWinery(winery) {
			this.showLoading()
			const response = await DataService.findWinery(winery)
			const wineries = DataMappingService.mapFindWineryResponse(response)
			const foundGeneralWinery = wineries.find(w => w.venue.id === null) !== undefined

			this.hideLoading()
			if ( wineries.length > 0 && foundGeneralWinery ) {
				return false
			} else {
				return true
			}
		},
		async sendCreateWineryRequest(winery) {
			this.showLoading()
			const postModel = DataMappingService.mapWineryUiToWineryPostModel(winery)
			await DataService.postWinery(postModel)
				.then((response) => {
					this.hideLoading()
					const wineryResponse = DataMappingService.mapPostWineryResponse(response)
					this.$emit('wineryAdded', wineryResponse)
					this.hideInput()
					this.resetUI()
					this.showSuccessAlert(this.$t('winelist.addWineModal.addedNewWinery'))
				})
				.catch(() => {
					this.showErrorAlert(this.$t('errors.savingError'))
					this.hideLoading()
				})
		},
		showLoading() {
			this.loading = true
		},
		hideLoading() {
			this.loading = false
		},
		toggleInput() {
			this.inputIsHidden = !this.inputIsHidden
			this.resetUI()
		},
		hideInput() {
			this.inputIsHidden = true
		},
		resetUI() {
			this.wineryName = ''
			this.resetSelectedRegion()
			this.resetSelectedLand()
			this.resetAlert()
		},
		resetSelectedRegion() {
			this.selectedRegion = {
				text: this.$t('general.dropdownText'),
				value: null
			}
			if (this.$refs.regionSearch != null)
				this.$refs.regionSearch.deleteSearch()
			
		},
		resetSelectedLand() {
			this.selectedLandName = {
				text: this.$t('general.dropdownText'),
				value: null
			}
			if (this.$refs.landSearch != null) {
				this.$refs.landSearch.deleteSearch()
			}
		},
		showErrorAlert(msg) {
			this.$root.$emit('showCreateWinesStep1Alert', { message: msg, type: 'warning'})
		},
		showSuccessAlert(msg) {
			this.$root.$emit('showCreateWinesStep1Alert', { message: msg, type: 'success' })
		},
		resetAlert() {
			this.$root.$emit('hideCreateWinesStep1Alert')
		},
		checkIfCountryIsSelected() {
			return this.selectedLand.value === undefined || this.selectedLand.value === null || this.selectedLand.value === ''
		},
	},
	watch: {
		selectedLand: function (newVal, oldVal) {
			if (newVal.value !== oldVal.value) {
				this.resetSelectedRegion()
			}
		}
	},
	computed: {
		regionOptions() {
				if (this.selectedLand != null && this.selectedLand.regions != null) {
						return this.selectedLand.regions.map(region => {
							const title = region.localeKey ? this.$t('regions.' + region.localeKey) : region.title
							return { text: title, value: region.id }
						})
				} else {
						return []
				}
		}
	}
}

</script>