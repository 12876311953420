<template>
  <div class="choose-wine-table-container modalView">
    <b-table ref="wineryTable" sticky-header hover :items="filteredWineryList" :fields="wineryFields" :busy="loading" show-empty
      select-mode="single" selectable @row-selected="onWineryRowSelected">
      <template #table-busy>
        <div class="text-center my-2">
          <LoadingAnimation />
        </div>
      </template>
      <template #empty>
        <p class="noResults">{{ $t('winelist.addWineModal.noResultsWinery') }}</p>
      </template>
			<template #cell(land.localeKey)="data">
        <span v-if="data.value">{{ $t('countries.' + data.value) }}</span>
        <span v-else>{{ data.item.land.title }}</span>
      </template>
      <template #cell(region.localeKey)="data">
        <span v-if="data.value">{{ $t('regions.' + data.value) }}</span>
        <span v-else>{{ data.item.region.title }}</span>
      </template>
      <template #cell(selectedWinery)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>

		<AddWineryRow @wineryAdded="handleWineryAdded"/>

    <div class="spacer xs"></div>

  </div>

</template>

<script>
import * as Utils from '@/utils/functions'
import AddWineryRow from '@/components/modals/addWine/step1/AddWineryRow.vue'

export default {
  name: 'ChooseWinery',
	mounted() {
    this.$root.$on('changedWineryFilter', (filter) => {
      this.wineryFilter = filter
    })
  },
  data() {
    return {
      selectedWinery: {},
      selectedRegion: {
        text: '',
        value: null
      },
      selectedLand: {
        text: this.$t('general.dropdownText'),
        value: null
      },
      loading: false,
			wineryList: this.wineries,
      wineryFields: [
        {
          key: 'title',
          label: this.$t('general.winery')
        },
        {
          key: 'land.localeKey',
          label: this.$t('general.country')
        },
				{
          key: 'region.localeKey',
          label: this.$t('general.region')
        },

      ],
			wineryFilter: ''
    }
  },
  props: {
    wineries: {
      type: Array,
      required: true
    }
  },
  components: {
		AddWineryRow
  },
  methods: {
    onWineryRowSelected(items) {
      if (items.length > 0) {
        this.selectedWinery = items[0]
        this.selectedWinery['id'] = items[0].id
        this.wineryId = items[0].id
        this.selectedRegion.value = items[0].region.id
        this.selectedLand.value = items[0].land.id
        Utils.assignObjectFromPath(this.selectedWinery, ['land', 'value'], items[0].land.id)
        this.$emit('winerySelected', this.selectedWinery);
      } else {
        this.selectedWinery = {}
        this.$emit('winerySelected', this.selectedWinery);
      }
    },
		handleWineryAdded(winery) {
			this.wineryList.push(winery)
			this.selectWineryRow(winery)
		},
		selectWineryRow(winery) {
      this.$nextTick(() => {
        const index = this.$refs.wineryTable.items.findIndex(item => item.id === winery.id)
        this.$refs.wineryTable.clearSelected()
        this.$refs.wineryTable.selectRow(index)
      })
			
		}
			
  },
	computed: {
    filteredWineryList() {
      return this.wineryList.filter((winery) => winery.title.toLowerCase().includes(this.wineryFilter.toLowerCase()))
    },
  },
}
</script>

<style scoped></style>
