<template>
	<div>
		<b-row class="supplierRow mb-3" v-for="purchasePrice in filterDeletedPurchasePrices" v-bind:key="purchasePrice.id">
					<b-col cols="6">
							<span>{{$t('general.supplier')}}</span>
							<SearchDataList 
								:isLastSearch="false"
								:searchFromOutside="purchasePrice.wineSupplier.data.attributes.title"
								:autoSuggestComplete="getPurchasePriceSupplierOptions(purchasePrice)"
								:itemClickedFunction="setValueFromAutoSuggestField.bind(this, purchasePrice)"
							/>
					</b-col>
					<b-col cols="5">
							<span>{{$t('general.ekNet')}} {{ currency }}</span>
							<input @input="getPurchasePricesAvg" min="0" type="number" v-model="purchasePrice.price" >
					</b-col> 
					<b-col cols="1" class="deleteCol">
							<button class="iconButton" @click="flagDeletePurchasePrice(purchasePrice.id)" >
									<svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM3 6h8v10H3V6zm7.5-5-1-1h-5l-1 1H0v2h14V1h-3.5z" fill="#0CBA4A" fill-rule="nonzero"/>
									</svg>
							</button>
					</b-col>
			</b-row>
		<b-row class="bezugsquelleRow mt-2 mb-4">
				<b-col> 
						<button v-if="selectedWineCanAddWineSupplier" class="iconButton add" @click="addEmptyPurchasePrice">
							{{$t('general.supplier')}}
						</button>
				</b-col>
		</b-row>
	</div>
</template>

<script>

import Constants from '@/utils/Constants'
import { mapGetters } from 'vuex'
import * as Utils from '@/utils/functions'
import SearchDataList from '@/components/SearchDataList.vue'

export default {
	name: 'PurchasePricesEdit',
	props: {
		selectedWine: Object,
		venueSupplierOptions: Array,
		currency: String,
	},
	data () {
		return {
			purchasePrices: this.selectedWine.attributes.purchasePrices,
		}
	},
	methods: {
		getPurchasePriceSupplierOptions(purchasePrice) {
			let options = [];
			const venueSupplierOption = this.venueSupplierOptions.find(supplier => {
					if (purchasePrice.wineSupplier.data) {
							return supplier.value == purchasePrice.wineSupplier.data.id
					}
					return false
			})

			if (purchasePrice.wineSupplier.data && purchasePrice.wineSupplier.data.id && venueSupplierOption) {
					options.push({
							value: purchasePrice.wineSupplier.data.id,
							text: venueSupplierOption.text
					})
			}
			if (this.purchasePrices != null) {
					this.venueSupplierOptions.forEach((supplier) => { 
							const found = this.purchasePrices.find(p => {
									return p.wineSupplier.data != null && p.wineSupplier.data.id === supplier.value
							})
							
							if (typeof found == "undefined") {
									options.push(supplier);
							}
					})
			}
			
			return options;
		},
		wineSupplierText(wineSupplierId) {
			const generalText = this.$t('general.dropdownText')
			return Utils.getWineSupplierText(generalText, wineSupplierId, this.venueSupplierOptions)
		},
		addEmptyPurchasePrice() {
			if (this.purchasePrices == null || this.purchasePrices == undefined) {
					//set empty array if there are not yet any prices
					this.purchasePrices = [];
			}
			//create tempId: vuejs needs a key for its for-loop-list binding
			const tempId = `tempIdPurchasePrice_${this.purchasePrices.length}`

			this.purchasePrices.push({
					id: tempId,
					wineSupplier : {
							data: {
								id: null,
								attributes: {
									title: ""
								}
							}
					},
					createNew: true,
					price: 0.0,
			})
		},
		flagDeletePurchasePrice(purchasePriceId) {
			var purchasePrices = JSON.parse(JSON.stringify(this.purchasePrices))
			for (var i = 0; i < purchasePrices.length; i++) {
					var purchasePrice = purchasePrices[i]
					if (purchasePriceId == purchasePrice.id) {
							purchasePrice[Constants.SHOULD_DELETE] = true
							purchasePrices[i] = purchasePrice
							break
					}
			}
			//trigger compute method
			this.purchasePrices = JSON.parse(JSON.stringify(purchasePrices))
		},
		setValueFromAutoSuggestField(purchasePrice, supplier) {
			purchasePrice.wineSupplier.data.id = supplier.value
		},
		getPurchasePricesAvg() {
			var purchasePricesAvg = 0
			var sumOfPrices = 0
			for (var i = 0; i < this.purchasePrices.length; i++) {
					var purchasePrice = this.purchasePrices[i]
					if (purchasePrice.price != null && purchasePrice.price != undefined) {
							purchasePrice.price = parseFloat(purchasePrice.price)
					}
					sumOfPrices += purchasePrice.price
			}
			purchasePricesAvg = sumOfPrices / this.purchasePrices.length
			this.$emit('purchasePricesAvgChanged', purchasePricesAvg)
			
		},
	},
	components: { SearchDataList},
	computed: {
		...mapGetters(["getVenue"]),
		filterDeletedPurchasePrices() {
			return Utils.getNotDeletedPurchasePrices(this.purchasePrices);
		},

		selectedWineCanAddWineSupplier() {
			var foundSuppliers = 0
			if (this.purchasePrices != null) {
					this.venueSupplierOptions.forEach((supplier) => {
							const found = this.purchasePrices.find(p => p.wineSupplier.data && p.wineSupplier.data.id === supplier.value)                    
							if (typeof found !== "undefined") {
									foundSuppliers++
							}
					});
			}
		
			const foundAllWineSuppliers = this.venueSupplierOptions.length > 0 && foundSuppliers == this.venueSupplierOptions.length
			return !foundAllWineSuppliers
		},
	},
}

</script>